<template>
    <div>
		<v-container class="my-2  text-left">
			<v-dialog
				ref="dialog"
				v-model="modal"
				:return-value.sync="date"
				persistent
				width="350px"
			>
				<template v-slot:activator="{ on }">

				<v-btn
					v-model="date"
					readonly
					v-on="on"
					color="indigo"
					dark
					class="mr-5 mb-4"
				>
					{{ date }} 
				</v-btn>
				</template>
				<v-date-picker 
					v-model="date" 
					scrollable 
					type="month" 
					@change="dateChange(date)"
				>
					<v-spacer></v-spacer>
					<v-btn 
						text
						color="primary" 
						@click="modal = false"
					>
						Anuluj
					</v-btn>
					<v-btn 
						text 
						color="primary" 
						@click="$refs.dialog.save(date)"
					>
						OK
					</v-btn>
				</v-date-picker>
			</v-dialog>
		</v-container>
		<div class="d-flex flex-row align-baseline">
			<v-text-field
				:value="getTaskSearchCopy"
				@input="setTaskSearchCopy"
				label="Filtruj"
				single-line
				hide-details
				class="mb-3 mr-5"
			></v-text-field>
			<v-switch 
				v-model="getSwitchChecked" 
				@change="updateFilterSwitchChecked()"
				label="Sprawdzone" 
				class="mr-3"
			></v-switch>
			<v-switch 
				v-model="getSwitchEnd" 
				@change="updateFilterSwitchEnd()"
				label="Zakończone" 
				class="mr-4"
			></v-switch>
			<!-- <v-switch 
				v-model="getSwitchAccepted" 
				@change="updateFilterSwitchAccepted()"
				label="Zaakceptowane" 
				class="mr-3"
			></v-switch> -->
			<v-switch
				v-model="getSwitchReject" 
				@change="updateFilterSwitchReject()"
				label="Anulowane"
			></v-switch>
		</div>
        <v-data-table
			:loading="!isDownloaded"
			:search="getTaskSearchCopy"
            :headers="headers"
            :items="filteredItems"
            item-key="id"
            class="elevation-2"
			:sort-by="['date']"
            :hide-default-footer="true"
            :disable-pagination="true"
            :sort-desc="false"
        >
            <template v-slot:item.clientId="{ item }">
                {{ item.clientId ? item.clientId.name : '' }}
            </template>
            <template v-slot:item.copywriterId="{ item }">
                {{ item.copywriterId ? item.copywriterId.name : '' }}
            </template>

            <template v-slot:item.date="{ item }">
				{{ item.date ? item.date.split("T")[0] : '' }}
            </template>

            <template v-slot:item.status="{ item }">
				<StatusChip :status="item.status" />
            </template>

            <template v-slot:item.actions="{ item }">

				<div class="d-flex align-center"> 
					<div>
						<v-btn
							color="green"
							class="mt-1 mb-1 mr-2 white--text"
							@click.stop="changeRoute(item._id)"
						>
							Zobacz
						</v-btn>
					</div>
					
				
				</div>
            </template>

        </v-data-table>
    </div>
</template>

<script>

import io from 'socket.io-client';
import StatusChip from '@/components/molecules/StatusChip';
export default {
	components: {
		StatusChip,	
	},
    data(){
        return{
			tasks: [],
			isDownloaded: false,
			clients: [],
			search: '',
			socket: io(this.url),
			date: this.$route.params.month,
            modal: false,
            headers: [
                { text: 'Zadanie', align: 'left', sortable: true, value: 'name', },
                { text: 'Klient', value: 'clientId', sortable: true  },
                { text: 'Copywriter', value: 'copywriterId', sortable: true  },
                { text: 'Deadline', value: 'date', sortable: true },
                { text: 'Znaki', value: 'characters', sortable: true  },
                { text: 'Status', value: 'status' , sortable: true },
                { text: 'Akcje', value: 'actions', sortable: false },
            ],
        }
	},
	computed: {
		role(){
			return this.$store.getters.getUserRole
		},
		// getSwitchAccepted: {
		// 	set(newValue) {
		// 		console.log(newValue);
		// 	},
		// 	get(){
		// 		return this.$store.getters.getSwitchAccepted
		// 	}
		// },
		getSwitchChecked: {
			set(newValue) {
				console.log(newValue);
			},
			get(){
				return this.$store.getters.getSwitchChecked
			}
		},
		getSwitchEnd:{
			set(newValue) {
				console.log(newValue);
			},
			get(){
				return this.$store.getters.getSwitchEnd
			}
		},
		getSwitchReject: {
			set(newValue) {
				console.log(newValue);
			},
			get(){
				return this.$store.getters.getSwitchReject
			}
		},
		getTaskSearchCopy(){
			return this.$store.getters.getTaskSearchCopy
		},
		filteredItems(){
            return this.tasks.filter((i) => {
                switch (i.status) {
                    // case 'Zaakceptowane':
                    //     if (this.getSwitchAccepted == false) {
                    //         return i
                    //     }
					// 	break;
                    case 'Sprawdzone':
                        if (this.getSwitchChecked == false) {
                            return i
                        }
						break;
					case 'Zakończone': 
						if (this.getSwitchEnd == false) {
                            return i
                        }
                        break;
                    case 'Anulowane':
                        if (this.getSwitchReject == false) {
                            return i
                        }
						break;
			
                    default:
                        return i
                }
			})
		}
	},
    methods:{
		correctDate(date){
			const newDate = new Date(date);
			return `${newDate.getHours()}:${newDate.getMinutes() < 9 ?  '0' + newDate.getMinutes() : newDate.getMinutes()}`
		},
		setTaskSearchCopy(text){
			this.$store.commit('updateFilterTaskSearchCopy', text);
		},
		updateFilterSwitchAccepted(){
			this.$store.commit('updateFilterSwitchAccepted', !this.getSwitchAccepted);
		},
		updateFilterSwitchChecked(){
			this.$store.commit('updateFilterSwitchChecked', !this.getSwitchChecked);
		},
		updateFilterSwitchReject(){
			this.$store.commit('updateFilterSwitchReject', !this.getSwitchReject);
		},
		updateFilterSwitchEnd(){
			this.$store.commit('updateFilterSwitchEnd', !this.getSwitchEnd);
		},
		getKorektorTasks(){
			setTimeout(() => {
				this.$axios.get(`${this.url}api/tasks/korektor/${this.$store.getters.getUserID}/${this.date}`)
					.then(resp => {
						this.tasks = resp.data;
						this.isDownloaded = true;
					});
				},1000)
		},
        getPosts(){
			this.getKorektorTasks()
        },
        changeRoute(id){
            this.$router.push(`task/${id}`);
        },
		dateChange(date){
            this.$router.push(`../../${this.role}/${date}`);
			this.getPosts()
        },
    },
    created(){
        this.getPosts();

        this.socket.on('newTaskAdded', () => {
            this.getPosts()
        });

        this.socket.on('taskDeleted', () => {
            this.getPosts()
		});

        this.socket.on('editedTask', () => {
            this.getPosts()
		});	
    },
}
</script>


