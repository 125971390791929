<template>
    <v-container>
        <TasksTableKorektor />
    </v-container>  
</template>

<script>
import TasksTableKorektor from '@/components/templates/TasksTableKorektor'
export default {
    components: {
        TasksTableKorektor
    }
}
</script>